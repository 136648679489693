import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './card.css';
import Modal from './modal';

function Card({ title, description, images, link }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const hasImage = images && images.length > 0;
  const backgroundImageStyle = hasImage ? {
    backgroundImage: `url(${images[0]})`,
    backgroundColor: 'white'
  } : {};

  const handleButtonClick = () => {
    if (link) {
      window.location.href = link;
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <div className={`project-card ${hasImage ? 'project-card-with-image' : ''}`}>
        <div className={`card-layer ${hasImage ? 'with-image' : ''}`}>
          <div className="card-label">
            <span className="card-title">{title}</span>
          </div>
        </div>
        <div className={`card-layer overlay-layer ${hasImage ? 'with-image' : ''}`} style={backgroundImageStyle}></div>
        <div className="mute-layer"></div>
        <button className="learn-more-button" onClick={handleButtonClick}>Learn More</button>
      </div>
      {isModalOpen && <Modal title={title} description={description} onClose={() => setModalOpen(false)} />}
    </>
  );
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.string,
};

export default Card;
