//{ title: 'Template', description: 'Description for card Template', images: ['path/to/image2a.jpg', 'path/to/image2b.jpg'] },
//Data for additional project cards

import beakd from './assets/images/Beakd.jpg';
export const projectData = [
  {
    name: 'NetSuite ERP',
    link: '/netsuite',
    cards: [
      { title: 'Switching Custom DirectScale Integration to Exigo', description: 'Client was using an existing, custom integration direct from DirectScale to NetSuite. They were switching from DirectScale to Exigo. I was the prime developer in charge of making changes to the existing, custom middleware solution without needing to rewrite an integration from scratch. Saving them time and money', images: ['https://cdn.prod.website-files.com/61408d1fb0d586ef10167b92/63d7769312524f53072442b0_6UBcnK1iDTQaatMERb0-Ka8jBvAJAxvU_nKbHwOTKS4.png'], moreDetails: true },
      { title: 'Custom Invoice Automation', description: 'Popular sunglass company had no way to automate sending customer invoices for their retailers. Using a combination of SuiteScript, saved searches, and custom records I was able to create a highly customizable automated invoices solution. This took over for an intern position freeing up 100% of a positions time to work on other matters', images: ['https://cdn.prod.website-files.com/61408d1fb0d586ef10167b92/63d7769312524f53072442b0_6UBcnK1iDTQaatMERb0-Ka8jBvAJAxvU_nKbHwOTKS4.png'], moreDetails: true },
      { title: 'Custom Customer Statements Automation', description: 'The CFO and head accountant at a popular eCommerce company were spending too much of their time sending customer statements. This custom solution used workflows, workflow action scripts, and custom searches to handle sending statements at customizable time intervals to customers with a statement balance.', images: ['https://cdn.prod.website-files.com/61408d1fb0d586ef10167b92/63d7769312524f53072442b0_6UBcnK1iDTQaatMERb0-Ka8jBvAJAxvU_nKbHwOTKS4.png'], moreDetails: true },
      { title: 'Custom nonMatrix Solution', description: 'A company was undergoing a restructuring of inventory items. They needed to maintain historical item data in NetSuite while changing Shopify itemization into a matrix structure. Since NetSuite doesnt have a native solution I created the necessary links and custom Celigo integrations to handle this change saving tens of thousands of dollars and thousands of hours of work time.', images: ['https://mma.prnewswire.com/media/1653390/celigo_Logo.jpg?p=facebook'] },
      { title: 'Custom Suitelet for Private Airline Solutions', description: 'A private airline broker needed a suitelet displaying custom data from various locations in NetSuite and the ability to alter the data on the records. This suielet handled all requirements from the client.', images: ['https://cdn.prod.website-files.com/61408d1fb0d586ef10167b92/63d7769312524f53072442b0_6UBcnK1iDTQaatMERb0-Ka8jBvAJAxvU_nKbHwOTKS4.png'], moreDetails: true },
      { title: 'Other Projects', description: '', images: [], link: '/projects' },

    ],
  },
  {
    name: 'Personal Projects',
    link: '/personal',
    cards: [
      { title: 'Beakd', description: 'Description for card A', images: [beakd], link: '/beakd', moreDetails: false },
    ],
  },
  {
    name: 'Other',
    link: '/other',
    cards: [
    ],
  },
];
