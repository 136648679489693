import React from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons';

function Projects() {
  return (
    <div className="App">
      <div className="top-section">
        <nav className="navbar">
          <div className="navbar-left">
            <span className="navbar-name">{'{ ELLSWORTH, DEREK }'}</span>
          </div>
          <div className="navbar-right">
            <a className="navbar-home" href="/">HOME</a>
            <a className="navbar-home" href="/projects">PROJECTS</a>
            <a className="navbar-home" href="/beakd">BEAKD</a>
          </div>
        </nav>
      </div>
      <footer className="footer">
        <p>2024 &copy; Derek Ellsworth</p>
        <a href="https://www.linkedin.com/in/derekellsworth/" target="_blank" rel="noopener noreferrer">
          https://www.linkedin.com/in/derekellsworth/
        </a>
        <p><FontAwesomeIcon icon={faEnvelopeRegular} /> derek@derkells.com</p>
      </footer>
    </div>
  );
}

export default Projects;
