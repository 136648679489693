import React, { useState } from 'react';
import Slider from 'react-slick';
import './beakd.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import loginScreen from './assets/images/LoginScreen.png';
import mapScreen from './assets/images/MapScreen.png';
import beakdexScreen from './assets/images/BeakdexScreen.png';
import friendScreen from './assets/images/FriendScreen.PNG';
import pinDataScreen from './assets/images/PinData.PNG';
import profileScreen from './assets/images/ProfileScreen.png';
import createPin from './assets/images/CreatePin.png';
import SelectIconScreen from './assets/images/SelectIconScreen.png';

const images = [
  { src: loginScreen, text: '<b>Secure Sign Up</b><br>Sign up using your email. Your data is secure using Google\'s own Firebase Authentication. Using the app requires authenticating your email before access' },
  { src: mapScreen, text: '<b>View yours and your friends pins on the map!</b><br>Seamlessly integrated with Apple Maps. You can find existing pins and add new ones to track the locations and the # of birds seen!' },
  { src: createPin, text: '<b>Create pins to track the birds you\'ve seen on the map</b><br>Click on the map to add a new pin! You can select the bird and the number of birds seen' },
  { src: beakdexScreen, text: '<b>The "Beakdex" screen!</b><br>The Beakdex is where the magic happens. Think of it as the <i>Pokedex</i> but for birds! Currently, this only supports tracking the # of birds you\'ve seen. But future updates will include pictures, bird calls, and more!' },
  { src: friendScreen, text: '<b>View, add and remove freinds</b><br>Add new friends! See their stats and view the most recent bird sighting of theirs! Show off your rare bird icons and borders as you collect more birds!' },
  { src: profileScreen, text: '<b>Your profile</b><br>View your stats, pins, edit personal information, select your icon, and your border!' },
  {src: SelectIconScreen, text: '<b>Select your icon!</b><br>Choose an icon and border for your profile. Unlock more with more bird sightings!' },
  { src: pinDataScreen, text: '<b>Your pins</b><br>View more specific data about your pins! This lets you remove accidental or undesired pins, display specific pins on the map, and more!' },
];

function Beakd() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.createElement('form');
    form.action = 'https://docs.google.com/forms/d/e/1FAIpQLSe0WN5Pvf3Tydq43Jf2ZlwZ3zXPX01NB4MB0tYm741OkQ_-4g/formResponse'; 
    form.method = 'POST';
    form.target = 'hidden_iframe';
    
    const nameField = document.createElement('input');
    nameField.type = 'hidden';
    nameField.name = 'entry.2049684173'; 
    nameField.value = name;
    form.appendChild(nameField);

    const emailField = document.createElement('input');
    emailField.type = 'hidden';
    emailField.name = 'entry.862026045'; 
    emailField.value = email;
    form.appendChild(emailField);
    
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
    
    setName('');
    setEmail('');
    closeModal();
    alert("You've been added to the waitlist!");
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="beakd-App">
      <header className="beakd-header">
        <h1 className="beakd-title">Beakd.</h1>
        <p className="beakd-subtitle">Get in touch with nature, and your Friends! Beakd. is a passion project of mine where you track bird sightings and share them with your friends! Feel free to reach out if you are interested in testing or learning more! Coming Soon-ish to the iOS store</p>
        <button className="beakd-waitlist-button" onClick={openModal}>Sign up for the beta testing waitlist</button>
      </header>
      <div className="beakd-top-section">
        <nav className={`beakd-navbar ${isNavOpen ? 'open' : ''}`}>
          <div className="beakd-navbar-toggle" onClick={toggleNav}>
            <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />
          </div>
          <div className="beakd-navbar-content">
            <div className="beakd-navbar-left">
              <span className="beakd-navbar-name">{'Beakd.'}</span>
            </div>
            <div className="beakd-navbar-right">
              <a className="beakd-navbar-home" href="/">Back to Portfolio</a>
            </div>
          </div>
        </nav>
        <div className="beakd-carousel">
          <div className="beakd-carousel-left">
            <Slider
              {...settings}
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
            >
              {images.map((image, index) => (
                <div key={index} className="beakd-slide">
                  <img src={image.src} alt={`Beakd ${index}`} style={{ width: '100%', height: 'auto' }} />
                </div>
              ))}
            </Slider>
          </div>
          <div className="beakd-carousel-right">
            <Slider
              {...settings}
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
            >
              {images.map((image, index) => (
                <div key={index} className="beakd-slide-text">
                  <div 
                    className="beakd-image-text" 
                    dangerouslySetInnerHTML={{ __html: image.text }}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <footer className="beakd-footer">
        <p>2024 &copy; Derek Ellsworth</p>
        <a href="https://www.linkedin.com/in/derekellsworth/" target="_blank" rel="noopener noreferrer">
          https://www.linkedin.com/in/derekellsworth/
        </a>
        <p><FontAwesomeIcon icon={faEnvelopeRegular} /> derek@derkells.com</p>
      </footer>
      {isModalOpen && (
        <div className="beakd-modal">
          <div className="beakd-modal-content">
            <span className="beakd-close" onClick={closeModal}>&times;</span>
            <h2 className="beakd-modal-title">Join the Beta Testing Waitlist</h2>
            <p className='beakd-modal-description'>Be added to the beta testing waitlist for a chance to get early access to builds and testing! At this stage in development you can have a big say in the future of the app!</p>
            <form onSubmit={handleSubmit}>
              <input 
                type="text" 
                value={name} 
                onChange={handleNameChange} 
                placeholder="Enter your name" 
                required 
              />
              <input 
                type="email" 
                value={email} 
                onChange={handleEmailChange} 
                placeholder="Enter your email" 
                required 
              />
              <button type="submit">Sign Up</button>
            </form>
          </div>
        </div>
      )}
      <iframe name="hidden_iframe" style={{ display: 'none' }} title="hidden_iframe"></iframe>
    </div>
  );
}

export default Beakd;