import React from 'react';
import { useNavigate } from 'react-router-dom';
import './BlurModal.css';

const BlurModal = ({ showModal }) => {
  const navigate = useNavigate();

  const handleReturnToBeakd = () => {
    navigate('/beakd');
  };

  return (
    <div className={`modal-overlay ${showModal ? 'show' : ''}`}>
      <div className="modal">
        <div className="modal-content">
          <h2>Site Under Construction</h2>
          <p>This site is currently under construction. Please check back later.</p>
          <button onClick={handleReturnToBeakd} className="return-button">Return to Beakd.</button>
        </div>
      </div>
    </div>
  );
};

export default BlurModal;
