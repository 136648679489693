import React, { useRef, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope as faEnvelopeRegular } from '@fortawesome/free-regular-svg-icons';
import Card from './card';
import { projectData } from './data';
import Projects from './projects';
import Beakd from './beakd';
import BlurModal from './BlurModal';

function Home() {
  const footerRef = useRef(null);
  const projectsRefs = useRef([]);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = (index) => {
      if (projectsRefs.current[index]) {
        const isCurrentlyScrolling = projectsRefs.current[index].scrollLeft > 0;
        if (isCurrentlyScrolling) {
          projectsRefs.current[index].classList.add('no-margin');
        } else {
          projectsRefs.current[index].classList.remove('no-margin');
        }
      }
    };

    const refs = projectsRefs.current;

    refs.forEach((ref, index) => {
      if (ref) {
        ref.addEventListener('scroll', () => handleScroll(index));
      }
    });

    return () => {
      refs.forEach((ref, index) => {
        if (ref) {
          ref.removeEventListener('scroll', () => handleScroll(index));
        }
      });
    };
  }, []);

  return (
    <div className="App modal-open">
      <div className="top-section">
        <nav className="navbar">
          <div className="navbar-left">
            <span className="navbar-name">{'{ ELLSWORTH, DEREK }'}</span>
          </div>
          <div className="navbar-right">
            <Link className="navbar-home" to="/">HOME</Link>
            <Link className="navbar-home" to="/projects">PROJECTS</Link>
            <Link className="navbar-home" to="/beakd">BEAKD</Link>
          </div>
        </nav>
        <header className="App-header">
          <div className="header-content">
            <h1 className="name-title">Derek Ellsworth</h1>
            <p className="job-title">Software Development (Site Under Construction)</p>
            <button className="contact-me-button" onClick={scrollToFooter}>
              <FontAwesomeIcon icon={faEnvelopeRegular} /> Contact Me
            </button>
          </div>
        </header>
      </div>
      <div className="bottom-section">
        <div className="projects-title">
          <h2>Projects</h2>
        </div>
        {projectData.filter(project => project.cards.length > 0).map((project, index) => (
          <div key={index} className="project-section">
            <h3 className="projects-heading">{project.name}</h3>
            <div className="projects" ref={el => projectsRefs.current[index] = el}>
              {project.cards.map((card, cardIndex) => (
                <Card key={cardIndex} title={card.title} images={card.images} description={card.description} link={card.link} />
              ))}
            </div>
          </div>
        ))}
      </div>
      <footer className="footer" ref={footerRef}>
        <p>2024 &copy; Derek Ellsworth</p>
        <a href="https://www.linkedin.com/in/derekellsworth/" target="_blank" rel="noopener noreferrer">
          https://www.linkedin.com/in/derekellsworth/
        </a>
        <p><FontAwesomeIcon icon={faEnvelopeRegular} /> derek@derkells.com</p>
      </footer>
      <BlurModal showModal={true} />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/beakd" element={<Beakd />} />
      </Routes>
    </Router>
  );
}

export default App;
